input {
  font-family: Roboto;
}

.input-control {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
  line-height: 18px;
  z-index: 900;
  height: 16px;
  padding-left: 25px;

  + .input-control {
    margin-top: 15px;
  }

  &::selection {
    background: transparent;
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.input-control__indicator {
  position: absolute;
  top: 0px;
  left: 0;
  height: 16px;
  width: 16px;
  background: var(--color-white);
  border: 1px solid var(--color-med-grey);
  border-radius: 4px;

  .input-control--radio & {
    border-radius: 50%;
  }

  // Hover and focus
  .input-control:hover input ~ &,
  .input-control input:focus ~ & {
    border: 1px solid var(--color-dash-border-grey);
  }

  // Checked
  .input-control input:checked ~ & {
    background: var(--color-white);
  }

  // Hover when checked
  .input-control:hover input:not([disabled]):checked ~ &,
  .input-control input:checked:focus ~ & {
    background: var(--color-white);
    border: 1px solid var(--color-dash-border-grey);
  }

  // Hide default browser input
  .input-control input:disabled ~ & {
    background: var(--color-med-grey-opacity-20);
    opacity: 0.6;
    border-color: var(--color-med-grey);
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;

    .input-control input:checked ~ & {
      display: block; // Show check
    }

    // Checkbox tick
    .input-control--checkbox & {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid var(--color-black-grey);
      border-width: 0 3px 3px 0;
      transform: rotate(40deg);
    }

    // Disabled tick colour
    .input-control--checkbox input:disabled ~ & {
      border-color: var(--color-black-opacity-50);
    }

    // Radio button inner circle
    .input-control--radio & {
      left: 50%;
      margin-left: -30%;
      top: 50%;
      margin-top: -30%;
      height: 60%;
      width: 60%;
      border-radius: 50%;
      background: var(--color-black-grey);
    }

    // Disabled circle colour
    .input-control--radio input:disabled ~ & {
      background: var(--color-black-opacity-50);
    }
  }
}


// start of small input controls
.input-control--sm {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px;
  font-family: Roboto;

  &--inline {
    margin-top: 0;
  }

  + .input-control--sm {
    margin-top: 2px;
  }

  &::selection {
    background: transparent;
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.input-control__indicator--sm {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background: transparent;
  border: 1px solid var(--color-med-grey);
  border-radius: 4px;

  .input-control--radio & {
    border-radius: 50%;
    line-height: 1;
  }

  // Hover and focus
  .input-control--sm:hover input ~ &,
  .input-control--sm input:focus ~ & {
    border: 1px solid var(--color-dash-border-grey);
  }

  // Checked
  .input-control--sm input:checked ~ & {
    background: var(--color-white);
  }

  // Hover when checked
  .input-control--sm:hover input:not([disabled]):checked ~ &,
  .input-control--sm input:checked:focus ~ & {
    background: var(--color-white);
    border: 1px solid var(--color-dash-border-grey);
  }

  // Hide default browser input
  .input-control--sm input:disabled ~ & {
    background: var(--color-med-grey-opacity-20);
    opacity: 0.6;
    border-color: var(--color-med-grey);
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;

    .input-control--sm input:checked ~ & {
      display: block; // Show check
    }

    // Checkbox tick
    .input-control--checkbox & {
      left: 4px;
      top: 1px;
      width: 6px;
      height: 11px;
      border: solid var(--color-black-grey);
      border-width: 0 3px 3px 0;
      transform: rotate(40deg);
    }

    // Disabled tick colour
    .input-control--checkbox input:disabled ~ & {
      border-color: var(--color-black-opacity-50)
    }

    // Radio button inner circle
    .input-control--radio & {
      left: 50%;
      margin-left: -4px;
      top: 50%;
      margin-top: -4px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: var(--color-dark-grey);
    }

    // Disabled circle colour
    .input-control--radio input:disabled ~ & {
      background: var(--color-black-opacity-50);
    }

    // Indeterminate tick
    .input-control--checkbox input:indeterminate + & {
      left: 2px;
      top: 5px;
      width: 10px;
      height: 0px;
      border: solid var(--color-black-grey);
      border-width: 0 0 4px 0;
      transform: rotate(0deg);
      border-radius: 2px;
      display: block;           
    }
  }
}

.input-control__helper {
  font-size: 10px;
  position: relative;
  background: var(--color-med-grey);
  border-radius: 8px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  padding-left: 1px;
  display: inline-block;
  margin-left: 5px;
  top: -2px;
  font-weight: 800;

  &:hover {
    &:after {
      content: attr(data-helper);
      position: absolute;
      top: -12px;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.2;
      left: 30px;
      background: var(--color-med-grey);
      padding: 10px 15px;
      text-align: left;
      width: 300px;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 10px solid var(--color-med-grey);
      position: absolute;
      left: 20px;
      top: 1px;
    }
  }
}

select.form-control:not([size]):not([multiple]) {
  height: 38px;
  border-radius: 0;
  appearance: none;
}

.select-wrapper {
  position: relative;

  &:before {
    content: '\f0dd';
    font-family: 'FontAwesome';
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: .875rem;
    background-color: transparent;
    pointer-events: none;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.calendar-wrapper {
  margin: 0;
  position: relative;

  &:after {
    font-family: "FontAwesome";
    content: '\f073';
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 14px;
    color: var(--color-dark-grey);
    pointer-events: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

label {
  margin-top: 10px;
  font-family: Roboto;
  font-size: 12px;
}

.custom-select {
  border-radius: 0;
}

// Toggle Switch
.oes-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  vertical-align: middle;

  &.disabled {
    opacity: .5;
  }
}

.oes-switch input {display:none;}

.oes-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-med-grey);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;
}

.oes-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-white);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .oes-slider {
  background-color: var(--color-brand);
}

input:focus + .oes-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .oes-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

