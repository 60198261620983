// This is for ag-grid non enterprise
// div.ag-root {
//   -webkit-user-select:text;
//   -moz-user-select:text;
//   -ms-user-select:text;
//   user-select:text;
// }

.ag-theme-bootstrap {
  font-family: 'Roboto';
  font-size: 12px;
  background-color: transparent;

  .ag-menu {
    z-index: 9;
  }

  .ag-side-bar {
    background-color: unset;
  }

  .ag-header {
    .ag-header-group-cell-label {
      background-color: var(--color-light-grey);
      padding: 0 0 0 10px;
      font-family: 'Roboto';
    }

    .ag-pinned-right-header {
      border-left: solid 1px var(--color-med-grey);
    }

    .ag-header-cell-text {
      font-weight: bold;
      font-size: 12px;
    }

    .ag-icon-checkbox-unchecked {
      height: 18px;
    }

    .ag-header-cell.checkboxColumn {
      padding: 4px 12px 0 10px;
    }


    span.ag-icon.ag-icon-checkbox-indeterminate,
    span.ag-icon.ag-icon-checkbox-checked {
      margin-top: -2px;
    }
  }

  .ag-rich-select {
    .ag-rich-select-value {
      .ag-icon-small-down {
        display: none;
      }
    }
  }

  .checkboxCell {
    padding: 2px 10px 0 8px !important;
  }

  .ag-body {
    background-color: transparent;
  }

  .ag-body-viewport {
    background-color: transparent;
    border-top: 6px solid var(--color-0-med-grey);
    margin-top: 6px;

    .ag-pinned-left-cols-viewport-wrapper {
      border-right: solid 1px var(--color-med-grey);
    }

    .ag-pinned-right-cols-viewport-wrapper {
      border-left: solid 1px var(--color-med-grey);
    }

    .ag-row {
//      height: 27px !important;
      border-bottom: solid 1px var(--color-med-grey);
      background-color: transparent;

      .ag-cell-value {
        a {
          color: var(--color-black);
          text-decoration: underline;
        }
        &.number {
          text-align: right;
          padding-right: 34px;
        }
      }
    }

    .ag-row-hover {
      background-color: var(--color-white);
    }

    .ag-row-odd {
      background-color: transparent;

      &.ag-row-hover {
        background-color: var(--color-white);
      }
    }

    .ag-cell.error {
      background-color: var(--color-light-red);
    }
  }

  .ag-cell-focus {
    &.no-focus {
      border: solid 1px transparent !important;
    }
    input.hasDatepicker {
      height: 23px;
    }
    &.ag-cell.checkboxCell {
      border: solid 1px transparent !important;
    }
  }

  .ag-input-wrapper {
    input:not([type]),
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="date"],
    input[type="datetime-local"] {
      border-color: var(--color-med-grey);
    }
  }
  .ag-floating-filter-body {
    input {
      padding-left: 1.6em;
      background-image: url(../images/search-grey.svg);
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: 4px;
      border: solid 1px var(--color-med-grey);
      font-size: 12px;
      line-height: 1;

      &:focus {
        outline: solid 1px var(--color-dark-grey);
      }

      &[disabled] {
        background-image: unset;
        padding-left: 0;
      }

      &[type='date'] {
        background-image: unset;
        padding-left: 3px;
      }
    }
  }

  .ag-floating-filter-body {
    oes-date-filter {
      input {
        background-image: unset;
      }
    }
  }

  .ag-filter {
    label.ag-set-filter-item {
      margin: 0;
    }

    .ag-filter-header-container {
      input.ag-filter-filter {
        width: 96%;
        margin: 0 2%;
        border: solid 1px var(--color-med-grey);

        &:focus {
          outline: solid 1px var(--color-dark-grey);
        }
      }
    }

    .ag-filter-body {
      padding: 0;

      input.ag-filter-filter {
        width: 96%;
        margin: 0 2%;
        border: solid 1px var(--color-med-grey);

        &:focus {
          outline: solid 1px var(--color-dark-grey);
        }
      }
    }
  }


  .ag-cell {
    text-overflow: clip !important;

    a {
      color: var(--color-black);
      text-decoration: underline;
    }

    &.link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .checkboxColumn {
    span {
      float: unset;
    }
  }

  .checkboxCell {
    text-align: center;
    border: solid 1px transparent !important;
    padding-top: 2px;
    text-overflow: unset;

    span {
      margin: 0;
      float: unset;
    }
  }

  .ag-cell-inline-editing {
    height: 24px;
    padding: 0 10px;

    input[type="text"]:focus,
    input[type="tel"]:focus,
    input[type="date"]:focus,
    input[type="datetime-local"]:focus {
      border-bottom: 2px solid var(--color-med-grey);
    }

    select {
      height: 27px;
    }
  }


  .ag-floating-filter-button {
    margin: 5px 0 0 5px;
    button {
      height: auto;
    }
  }
  .ag-icon-none {
    background-image: none;

    &::before {
      content: '\F0DC';
      font-family: 'FontAwesome';
    }
  }

  .ag-icon-desc {
    background-image: none;

    &::before {
      content: '\F0DD';
      font-family: 'FontAwesome';
    }
  }

  .ag-icon-asc {
    background-image: none;

    &::before {
      content: '\F0DE';
      font-family: 'FontAwesome';
    }
  }

  .ag-icon-filter {
    background-image: unset !important;

    &::before {
      content: '\f0b0';
      font-family: 'FontAwesome';
    }
  }

  .ag-icon-grip:before {
    opacity: .4;
  }

  select.ag-filter-select {
    &:focus {
      outline: 1px solid var(--color-dark-grey);
    }
  }

  .ag-overlay-loading-center {
    border: none !important;
    background-color: var(--color-dark-grey) !important;
    color: var(--color-white) !important;
    border-radius: 0 !important;
    padding: 10px 20px !important;
    font-size: 16px;
  }

  .ag-tool-panel {
    border-right: none;
  }

  .ag-status-bar {
    border: unset;
    font-family: 'Roboto';
    font-size: 12px;
  }

  .ag-header-cell-sorted-none {
    .ag-sort-none-icon {
      display: block !important;
    }
  }

  .not-editable {
    color: var(--color-dark-grey);
    background-color: var(--color-light-grey);
    &.ag-cell-focus {
      outline: unset !important;
      border: unset !important;
    }
  }

  .ag-ltr .ag-cell {
    border: 1px solid transparent !important;
  }

  .ag-cell-not-inline-editing {
    &.ag-cell-focus {
      border: solid 1px var(--color-dark-grey) !important;
      outline: solid 1px transparent !important;
    }
  }

  .ag-paging-page-summary-panel {
    button {
      background-color: var(--color-white);
      border: solid 1px var(--color-med-grey);
      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .ag-filter-condition {
    margin: 5px 0 0 0;
  }

  .ag-header-icon {
    &.ag-filter-icon {
      color: var(--color-red);
    }
  }
}

// reset text link
.reset {
  color: var(--color-brand);
  font-size: 10px;
  margin-left: 15px;

  &:hover {
    cursor: pointer;
    color: var(--color-brand-darken-5);
  }
}

// reload, export csv link
.reload,
.export-csv {
  color: var(--color-drop-down-grey) !important;
  font-size: 10px;
  margin-left: 15px !important;

  &:hover {
    cursor: pointer;
    color: var(--color-dark-grey) !important;
  }
}
