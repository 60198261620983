@import '~bootstrap/scss/bootstrap';

html, body, #root {
    height: 100%;
}


// ag-grid

@import '~ag-grid-community/dist/styles/ag-grid';
@import '~ag-grid-community/dist/styles/ag-theme-bootstrap';
@import './style/assets/scss/ag_grid_override';

.grid-container {
    height: 400px;
}


// theme

@import './style/theme-global';
@import './style/theme-odyssey';

@import './style/assets/scss/typography';
@import './style/assets/scss/buttons';
@import './style/assets/scss/input_controls';
@import './style/assets/scss/fixed_layout';

body {
    @include theme-global;
    @include theme-odyssey;
    font-family: Roboto, serif;
}

@import './style/styles';


// map

.map-container {
    height: 500px;
}
