// editor markers

// node
.node-marker {
}

.node-marker:hover {
    filter: url('#highlight');
}

.node-marker-offline {
    fill: #ff7f00;
    stroke: black;
    stroke-width: 1;
}

.node-marker-online {
    fill: #0dbc8c;
    stroke: yellow;
    stroke-width: 3;
}

.node-marker-connection {
    stroke: deepskyblue;
    stroke-width: 1px;
}


// line

.line-marker {
}

.line-marker-hitbox {
    stroke: black;
    stroke-opacity: 0;
    stroke-width: 9px;
}

.line-marker-hitbox:hover + .line-marker {
    filter: url('#highlight');
}

// connection
.line-marker-offline {
    stroke: red
}

.line-marker-online {
    stroke: green;
}

// level
.line-marker-phase1 {
    stroke-dasharray: none;
}

.line-marker-phase3wye {
    stroke-dasharray: 3 2 3 2 3 2 3 5;
}

.line-marker-phase3delta {
    stroke-dasharray: 3 2 3 2 3 5;
}

// voltage
.line-marker-low {
    stroke-width: 1px;
}

.line-marker-medium {
    stroke-width: 2px;
}


// joint

.joint-marker {
    fill: yellow;
    stroke: black;
    stroke-width: 1px;
}


// generation

.generation-marker {
    fill: #0dbc8c;
    stroke: #000;
    stroke-width: 1px;
}


// tool markers

.line-tool-end-marker {
    fill: #f99;
    stroke: #000;
    stroke-width: 1px;
}

.line-tool-line-marker {
    stroke: #f00;
    stroke-width: 1px;
}

.generation-tool-marker {
    fill: #0dbc8c;
    stroke: #000;
}

.generation-tool-noop {
    fill-opacity: 0;
    stroke: #f00;
    stroke-dasharray: 4, 1;
}
